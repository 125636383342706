.workSummary {
    width: 300px;
    margin-right: 40px;
}

.workSummary li {
    list-style-type: square;
    margin-bottom: 10px;
}

.workSummary span {
    color: rgb(252, 244, 165);
}

@media screen and (max-width: 450px) {
    .workSummary {
        width: 80vw;
        margin-right: 10px;
    }
}
