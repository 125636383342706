.skillOutline {
    max-width: 300px;
    width: 30vw;
    min-width: 210px;
    height: 7px;
    min-height: 7px;
    margin: 35px 50px 7px 0;
    outline: 1px solid grey;
    outline-offset: 3px;
    transition: margin 1s;
}

.skillBar {
    background-color: rgb(230, 230, 230);
    height: 100%;
}

.skillSubtext {
    margin: 0 50px 4px;
    color: rgb(252, 244, 165);
    transition: margin 1s;
}

.statsContainer {
    display: flex;
    flex-wrap: wrap;
    width: 330px;
    gap: 20px;
    justify-content: space-between;
    margin: 30px 50px 0 0;
}

.statsValue {
    text-align: center;
    color: rgb(252, 244, 165);
}

.statsSubtext {
    width: 330px;
    margin: 15px 50px 0 0;
    font-weight: 1;
}

.educationSummary {
    width: 300px;
    margin-right: 40px;
}

.educationSummary li {
    list-style-type: square;
    margin-bottom: 10px;
}

.educationSummary span {
    color: rgb(252, 244, 165);
}

@media screen and (max-width: 450px) {
    .educationSummary {
        width: 80vw;
        margin-right: 10px;
    }
}

@media screen and (max-height: 900px) {
    .skillOutline {
        margin-top: 15px;
    }
}

@media screen and (max-width: 450px) {
    .statsContainer {
        width: calc(100% - 90px);
        margin-right: 25px;
    }

    .statsSubtext {
        width: calc(100% - 75px);
        text-align: right;
        margin-right: 25px;
    }

    #nowrap {
        white-space: nowrap;
    }
}

@media screen and (min-height: 900px) {
    #about {
        justify-content: center;
    }
}
