html {
  height: 100%;
  width: 100%;
  position: fixed;
}

body {
  margin: 0; 
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #0D0D0D;
  font-family: Inconsolata;
  user-select: none;
  height: 100%;
  width: 100%;
  position: fixed;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: Inconsolata;
  src: url(../fonts/Inconsolata/Inconsolata-VariableFont_wdth\,wght.ttf);
}
