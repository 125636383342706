.projectSummary {
    margin: 8px 40px 0 0;
    width: 350px;
}

.projectSummary span {
    color: rgb(252, 244, 165);
}

#projects a {
    white-space: nowrap;
}

@media screen and (max-width: 800px) {
    .projectSummary {
        width: 300px;
    }
}

@media screen and (max-width: 450px) {
    .projectSummary {
        width: 83vw;
        margin-right: 10px;
    }

    #projects {
        /* width: 100%; */
        /* margin-right: 10px; */
        font-size: 13px;
    }
}
