.background {
    width: 100%;
    height: 100%;
}

.background::after {
    content: "";
    background-image: url("../images/filmgrain.png");
    height: 300%;
    width: 300%;
    opacity: 0.02;
    position: fixed;
    animation: grain 8s steps(10) infinite;
    left: -50%;
    top: -100%;
    pointer-events: none;
}

.outline {
    border: 1px solid grey;
    position: fixed;
    top: max(20px, 4vmin);
    bottom: max(20px, 4vmin);
    left: max(20px, 4vmin);
    right: max(20px, 4vmin);
    margin: auto;
}

.extraStars {
    position: relative;
    left: 2000px;
    opacity: 0;
}

.logo {
    position: absolute;
    top: 20px;
    right: -40px;
    width: 50px;
    cursor: pointer;
    transform: rotate(90deg);
    transition: all 1s;
    animation: fadeIn 1s forwards;
}

.sectionContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    color: #fff;
    width: 530px;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    transition: opacity 0.5s;
    overflow: hidden;
}

.overflow-container {
    height: fit-content;
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin: 15px 0 10px;
}

.overflow-container::-webkit-scrollbar {
    display: none;
}

.sectionContainer a {
    color: #fff;
    text-decoration: none;
}

.sectionTitle {
    width: 200px;
    font-size: 18px;
    border-bottom: 1px solid grey;
}

.sectionText {
    margin-top: 35px;
    font-weight: 1;
    font-size: 32px;
    margin-right: 50px;
}

.sectionSubtext {
    margin: 0 50px 4px;
}

.scroll-arrow {
    position: absolute;
    bottom: 4px;
    right: 10px;
    font-size: 30px;
    color: rgb(252, 244, 165);
    animation: bounce 2.5s ease-out infinite forwards;
    animation-delay: 2s;
}

@media screen and (min-width: 2000px) {
    .extraStars {
        opacity: 1;
    }
}

@media screen and (max-width: 1100px) {
    .sectionText {
        font-size: 25px;
    }
}

@media screen and (max-width: 860px) {
    .sectionText {
        font-size: 20px;
    }
}

@media screen and (max-width: 775px) {
    .sectionContainer {
        width: 100%;
        top: 200px;
        height: calc(100% - 200px);
    }
}

@media screen and (max-width: 530px) {
    .sectionContainer {
        width: 100%;
    }
}

@media screen and (max-width: 450px) {
    .logo {
        width: 40px;
        right: -31px;
    }

    .sectionContainer {
        top: 150px;
        height: calc(100% - 160px);
    }

    .scroll-arrow {
        font-size: 20px;
        right: 3.5px;
    }
}

@media screen and (max-width: 390px) {
    .logo {
        width: 35px;
        right: -28px;
    }
}

@media screen and (max-width: 370px) {
    .logo {
        width: 30px;
        right: -24px;
    }
}

@media screen and (max-height: 1150px) {
    .sectionText {
        margin-top: 15px;
    }
}

@media screen and (max-height: 1150px) {
    .sectionTitle {
        margin-top: 2vh !important;
    }
}

@keyframes bounce {
    0% {
        transform: translateY(0);
    }

    10% {
        transform: translateY(-10px);
    }

    20% {
        transform: translateY(0);
    }  
    100% {
        transform: translateY(0);
    }  
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes grain {

    0%,
    100% {
        transform: translate(0, 0)
    }

    10% {
        transform: translate(-5%, -10%)
    }

    20% {
        transform: translate(-15%, 5%)
    }

    30% {
        transform: translate(7%, -25%)
    }

    40% {
        transform: translate(-5%, 25%)
    }

    50% {
        transform: translate(-15%, 10%)
    }

    60% {
        transform: translate(15%, 0%)
    }

    70% {
        transform: translate(0%, 15%)
    }

    80% {
        transform: translate(3%, 25%)
    }

    90% {
        transform: translate(-10%, 10%)
    }
}