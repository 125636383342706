.jmLinuxContainer {
    position: absolute;
    bottom: 50px;
    left: 60px;
    color: #fff;
    line-height: 20px;
}

.input {
    border: none;
    background: transparent;
    color: rgb(252, 244, 165);
    font-family: Inconsolata;
    font-weight: bolder;
    outline: none;
    font-size: 16px;
}

.input::selection {
    color: black;
    background: rgb(252, 244, 165);
}

@media screen and (max-height: 895px),
screen and (max-width: 965px) {
    .jmLinuxContainer {
        display: none;
    }

    .helpActive {
        display: none;
    }

    .helpinActive {
        display: none;
    }
}

@media screen and (max-width: 800px) {
    .jmLinuxContainer {
        left: 30px;
        bottom: 25px;
    }
}
