.summary {
    color: #fff;
    width: 120px;
    line-height: 20px;
    position: absolute;
    bottom: 50px;
    right: 50px;
    transition: opacity 0.5s;
}

@media screen and (max-width: 895px) and (max-height: 1050px) {
    .summary {
        bottom: 25px;
        right: 25px;
    }
}

@media screen and (max-height: 700px) and (max-width: 800px) {
    .summary {
        bottom: 15px;
        right: 15px;
        width: 88%;
    }
}
