.loadGif {
    display: block;
    max-width: 350px;
    min-width: 200px;
    width: 40vw;
    margin: 25vh auto 0;
}

.loadOutline {
    max-width: 350px;
    width: 40vw;
    min-width: 210px;
    height: 7px;
    margin: 15px auto 0;
    outline: 1px solid grey;
    outline-offset: 3px;
}

.loadBar {
    background-color: rgb(230, 230, 230);
    width: 0;
    height: 100%;
    animation: load 4.5s ease-in-out forwards;
}

.loadText {
    max-width: 350px;
    width: 40vw;
    min-width: 210px;
    color: rgb(252, 244, 165);
    margin: 10px auto 0;
    font-size: 20px;
}

.loadText:after {
    overflow: hidden;
    display: inline-block;
    vertical-align: bottom;
    animation: ellipsis steps(4, end) 1500ms infinite;
    content: "\2026";
    width: 0px;
}

.loadGifStatic {
    display: block;
    max-width: 350px;
    min-width: 200px;
    width: 40vw;
    margin: 25vh auto 0;
    animation: fade 1s forwards 1s;
}

.loadOutlineStatic {
    max-width: 350px;
    width: 40vw;
    min-width: 210px;
    height: 7px;
    margin: 15px auto 0;
    outline: 1px solid grey;
    outline-offset: 3px;
    animation: fade 0.75s forwards 0.75s;
}

.loadTextStatic {
    max-width: 350px;
    width: 40vw;
    min-width: 210px;
    color: rgb(252, 244, 165);
    margin: 10px auto 0;
    font-size: 20px;
}

.letterFade {
    animation: fade 0.25s forwards;
}

@media screen and (max-width: 640px) {
    .loadText {
        font-size: calc(4px + 2.5vw);
    }

    .loadTextStatic {
        font-size: calc(4px + 2.5vw);
    }
}

@keyframes fade {
    0% {
        opacity: 100%;
    }

    100% {
        opacity: 0%;
    }
}

@keyframes ellipsis {
    to {
        width: 13px;
    }
}

@keyframes load {
    0% {
        width: 0%;
    }

    20% {
        width: 20%;
    }

    25% {
        width: 20%;
    }

    40% {
        width: 40%;
    }

    50% {
        width: 43%;
    }

    70% {
        width: 60%;
    }

    75% {
        width: 60%;
    }

    80% {
        width: 75%;
    }

    100% {
        width: 100%;
    }
}
